import React, { Component } from "react";
import {ReactComponent as Copyright} from '../images/copyright.svg';
import {ReactComponent as Logo} from '../images/Logo.svg';
import {Container} from 'react-bootstrap';
import {ReactComponent as LinkedIn} from '../images/linkedin.svg';
import {ReactComponent as Mail} from '../images/email.svg';
import {ReactComponent as Github} from '../images/giithub.svg';
import {ReactComponent as Vsco} from '../images/vsco.svg';
import {Row, Col} from 'react-bootstrap';

import '../App.css';

class BottomBar extends Component{
    render()
    {
        return(
            <Container className="bottom" fluid>
                {/* <Row style={{ paddingLeft: "1%" }} className="icons">   
                    <Col xs={4} className="right">
                        <a href="https://www.linkedin.com/in/likhita-suresh/" target="_blank" rel="noopener noreferrer"><LinkedIn className="social-icons" /></a>                           
                    </Col>                         
                    <Col xs={2}>                                         
                        <a href="mailto: likhitasuresh@gmail.com" target="_blank" rel="noopener noreferrer"><Mail className="social-icons"/> </a>                                                                   
                    </Col>
                    <Col xs={1}>   
                    <a href="https://github.com/likhitasuresh" target="_blank" rel="noopener noreferrer"><Github className="social-icons"/></a>                                                                                                             
                    </Col>    
                    <Col xs={3}>
                        <a href="https://vsco.com/likhitasuresh" target="_blank" rel="noopener noreferrer"><Vsco className="social-icons" /></a>                                                                                                             
                    </Col>                           
                </Row> */}
                <Logo style={{ float: "right", paddingLeft: "1%"}}/>
                <p className="light">Likhita Suresh<br/>2020<Copyright className="copyright"/></p>
            </Container>
        )
    }
}

export default BottomBar;